import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not found" />
    <div
      css={`
        min-height: 'calc(100vh - 466px)';
      `}
    >
      <h1
        css={`
          text-align: 'center';
        `}
      >
        Page Not Found
      </h1>
      <p
        css={`
          text-align: 'center';
        `}
      >
        <Link to="/">Return to the home page</Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
